<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Compare Scenarios'"
      />
      <common-page-loader v-if="loading" />
      <common-trs-card class="pa-5">
        <v-row>
          <v-col class="dropdown-container">
            <common-input-select
              v-model="activeScenario"
              multiple
              :items="scenarios"
              item-text="name"
              item-value="id"
              @change="updateWarrants"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(data, key) of scenarios"
            :key="key"
            cols="12"
            md="6"
          >
            <v-card
              v-if="data.is_active"
              class="card-item mt-4 pa-0"
            >
              <div class="trsBack pa-4">
                <div class="ml-2">
                  <span
                    class="text-h3 primary--text text-capitalize font-weight-medium"
                  >
                    {{ data.name }}
                  </span>
                </div>
              </div>

              <v-divider />
              <div class="pa-4">
                <v-row dense>
                  <v-col cols="12">
                    <expansion-panel v-model="data.scenario_rounds" />
                  </v-col>
                  <v-col cols="12">
                    <v-card
                      class="card-item mt-4 pa-0"
                    >
                      <div class="trsBack pa-4">
                        <div class="ml-2">
                          <span
                            class="text-h3 font-weight-medium primary--text text-capitalize"
                          >
                            Waterfall Analysis
                          </span>
                          <v-row>
                            <v-col>
                              <div class="text--lighten-1 font-weight-medium">
                                {{ $currencyWithPrecision(data.additional_information.exit_amount) }}
                              </div>
                              <div class="trsText--text text-body-2 text--lighten-1 mt-1">
                                Exit Value
                              </div>
                            </v-col>
                            <v-col>
                              <div class="text--lighten-1 font-weight-medium">
                                {{ $currencyWithPrecision(data.additional_information.price_per_share) }}
                              </div>
                              <div class="trsText--text text-body-2 text--lighten-1 mt-1">
                                Common Share Price
                              </div>
                            </v-col>
                            <v-col>
                              <div class="text--lighten-1 font-weight-medium">
                                {{ data.additional_information.exits_on }}
                              </div>
                              <div class="trsText--text text-body-2 text--lighten-1 mt-1">
                                Exit Date
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>

                      <v-divider />
                      <div class="pa-5">
                        <v-row>
                          <v-col>
                            <h4 class=" my-3 trsText--text text-h4 font-weight-medium">
                              Preferred Share Returns
                            </h4>
                            <common-trs-data-table
                              :headers="preferredReturnsTableHeader"
                              :items="data.preferred_returns"
                              hide-default-footer
                            >
                              <template v-slot:[`item.investors`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ item.investors }}
                                </span>
                              </template>
                              <template v-slot:[`item.priority`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ getNumberWithOrdinal(item.priority) }}
                                </span>
                              </template>
                              <template v-slot:[`item.net_payout`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ $currencyWithPrecision(item.net_payout) }}
                                </span>
                              </template>
                              <template v-slot:[`item.ownership`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ getPercentValue(item.ownership) }}
                                </span>
                              </template>
                            </common-trs-data-table>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h4 class=" my-3 trsText--text text-h4 font-weight-medium">
                              Common Share Returns
                            </h4>
                            <common-trs-data-table
                              :headers="commonReturnsTableHeader"
                              :items="data.common_returns"
                              hide-default-footer
                            >
                              <template v-slot:[`item.investors`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ item.investors }}
                                </span>
                              </template>
                              <template v-slot:[`item.shares_participating`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ item.shares_participating }}
                                </span>
                              </template>
                              <template v-slot:[`item.strike_acq_price`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ $currencyWithPrecision(item.strike_acq_price) }}
                                </span>
                              </template>
                              <template v-slot:[`item.net_payout`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ $currencyWithPrecision(item.net_payout) }}
                                </span>
                              </template>
                              <template v-slot:[`item.ownership`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ getPercentValue(item.ownership) }}
                                </span>
                              </template>
                              <template v-slot:[`item.total_return_dollar`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ $currencyWithPrecision(item.total_return_dollar) }}
                                </span>
                              </template>
                              <template v-slot:[`item.total_return_multiplier`]="{ item }">
                                <span class="trs--text text-body-1">
                                  {{ item.total_return_multiplier? roundOff(item.total_return_multiplier) : 'NA' }}
                                </span>
                              </template>
                            </common-trs-data-table>
                          </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                          <v-col>
                            Total  <span class="text-h3 font-weight-bold">  {{ $currencyWithPrecision(data.total_returns[0].net_payout) }} </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import moment from 'moment'
  import * as captableService from '@/components/common/captable/services/captable'
  import ExpansionPanel from './components/ExpansionPanel.vue'
  export default {
    name: 'CompareScenarios',

    components: {
      ExpansionPanel,
    },

    data: () => ({
      loading: false,
      scenarios: [],
      activeScenario: [],
      preferredReturnsTableHeader: [
        { text: 'Financing Rounds', value: 'investors', sortable: true },
        { text: 'Payment Priority', value: 'priority', sortable: true },
        { text: 'Liquidation Preference Payout', value: 'net_payout', sortable: true },
        { text: 'Ownership', value: 'ownership', sortable: true },
      ],
      commonReturnsTableHeader: [
        { text: 'Financing Rounds', value: 'investors', sortable: true },
        { text: 'Shares Participating', value: 'shares_participating', sortable: true },
        { text: 'Strike / Acquisition Pricet', value: 'strike_acq_price', sortable: true },
        { text: 'Common Shares Payout', value: 'net_payout', sortable: true },
        { text: 'Ownership', value: 'ownership', sortable: true },
        { text: 'Total Proceeds Received', value: 'total_return_dollar', sortable: true },
        { text: 'Mul', value: 'total_return_multiplier', sortable: true },
      ],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      scenarioList () {
        return this.$route.params.selectedIds
      },
    },

    mounted () {
      this.getWaterfallData()
    },
    methods: {
      roundOff (value) {
        return `${value.toFixed(2)}x`
      },
      getWaterfallData () {
        if (this.scenarioList) {
          this.scenarioList.forEach(element => this.waterfall(element))
        } else {
          return this.$router.push({
            name: 'Scenarios',
          })
        }
      },
      getNumberWithOrdinal (number) {
        if (number === null) return 'NA'
        const suffix = ['th', 'st', 'nd', 'rd']
        const v = number % 100
        return number + (suffix[(v - 20) % 10] || suffix[v] || suffix[0])
      },
      updateWarrants (event) {
        this.scenarios = this.scenarios.map((element) => {
          if (!event.includes(element.id)) {
            return { ...element, is_active: false }
          } else return { ...element, is_active: true }
        })
      },
      async waterfall (scenario) {
        this.loading = true
        try {
          const resp = await captableService.waterfall(this.captableId, scenario.id)
          this.loading = false
          this.scenarios.push({ ...resp.data, ...scenario, is_active: true })
          this.allScenariosList = this.scenarios
          this.activeScenario = this.scenarios
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to get scenario data. Please try again later.',
          })
        }
      },
      getDate (date) {
        return moment(date).format('MM/DD/YYYY')
      },
      calculateSum (roundData, key) {
        let total = 0
        if (roundData && roundData.investors && roundData.investors.length > 0) {
          return roundData.investors.reduce((accumulator, currentValue) => {
            (total += +currentValue[key])
            return total
          }, 0)
        } else {
          return total
        }
      },
      getPercentValue (value) {
        if (!isNaN(value)) {
          return (value * 100).toFixed(2) + '%'
        } else {
          return 'NA'
        }
      },
    },
  }
</script>

<style scoped>
  .dropdown-container{
    max-width: 18.75rem;
  }
</style>
