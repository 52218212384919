<template>
  <v-expansion-panels
    focusable
    multiple
  >
    <v-expansion-panel
      v-for="(roundData, index) in value"
      :key="index"
    >
      <v-expansion-panel-header>
        <span class="trsText--text text-body-1">{{ roundData.name }}</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- Defaut Summary Section -->
        <v-row class="summary-section">
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Pre-money valuation:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.pre_money_valuation) }}</span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Pre-issuance fully diluted shares:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ $number(roundData.pre_fully_diluted_shares) }}</span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Post-money valuation:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.post_money_valuation) }}</span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Post-issuance fully diluted shares:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ $number(roundData.post_fully_diluted_shares) }}</span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Price per share:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.price_per_share) }}</span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Dilution:</span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ getPercentValue(roundData.dilution) }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Closing date:</span>
            <span class="trsText--text text-body-1 font-weight-bold">{{ getDate(roundData.finally_closed_on) }}</span>
          </v-col>
        </v-row>
        <!-- Liquidation Preferences Section -->
        <v-divider
          v-if="roundData.liquidation_preference && roundData.liquidation_preference.include_interest"
          class="my-2"
        />
        <v-row
          v-if="roundData.liquidation_preference && roundData.liquidation_preference.include_interest"
          class="summary-section"
        >
          <v-col
            cols="12"
            class="trsText--text text-body-1 font-weight-bold text--lighten-1"
          >
            Liquidation Preferences
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Interest rate:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ parseFloat(roundData.liquidation_preference.interest_terms.rate).toFixed(2) }} %
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Multiple:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ roundData.liquidation_preference.multiple }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Compound period:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ roundData.liquidation_preference.interest_terms.type }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Participating cap:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ $currencyWithPrecision(roundData.liquidation_preference.participating_preference.participating_cap) }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Accrual period:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ roundData.liquidation_preference.interest_terms.period }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Previous class:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ roundData.liquidation_preference.previous_class }}
            </span>
          </v-col>
        </v-row>
        <!-- Equity incentive plan Section -->
        <v-divider class="my-2" />
        <v-row class="summary-section">
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Equity incentive plan shuffle:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ getPercentValue(roundData.equity_incentive_plan_shuffle) }}
            </span>
          </v-col>
          <v-col cols="6">
            <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
              Convert convertibles:
            </span>
            <span class="trsText--text text-body-1 font-weight-bold">
              {{ roundData.convert_convertibles ? 'Yes' : 'No' }}
            </span>
          </v-col>
        </v-row>
        <!-- Investor List Section -->
        <v-divider class="my-2" />
        <v-row>
          <v-col>
            <common-trs-data-table
              :headers="investorsTableHeader"
              :items="roundData.investors"
              :hide-default-footer="roundData.investors.length <= 5"
            >
              <template v-slot:[`item.invested_capital`]="{ item }">
                <span class="trs--text text-body-1">
                  {{ $currencyWithPrecision(item.invested_capital) }}
                </span>
              </template>
              <template v-slot:[`item.granted_shares`]="{ item }">
                <span class="trs--text text-body-1">
                  {{ $number(item.granted_shares) }}
                </span>
              </template>
              <template v-slot:[`item.ratio_fully_diluted`]="{ item }">
                <span class="trs--text text-body-1">
                  {{ getPercentValue(item.ratio_fully_diluted) }}
                </span>
              </template>
              <template v-slot:body.append="{}">
                <tr class="px-7 footer-bg">
                  <td>
                    Total
                  </td>
                  <td>
                    {{ $currencyWithPrecision(calculateSum(roundData, 'invested_capital')) }}
                  </td>
                  <td>
                    {{ $number(calculateSum(roundData, 'granted_shares')) }}
                  </td>
                  <td>
                    {{ getPercentValue(calculateSum(roundData, 'ratio_fully_diluted')) }}
                  </td>
                </tr>
              </template>
            </common-trs-data-table>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'ExpansionPanel',

    components: {
    },

    props: {
      value: {
        type: Array,
      },
    },

    data: () => ({
      investorsTableHeader: [
        { text: 'Investors', value: 'name', sortable: true },
        { text: 'Investor Capital', value: 'invested_capital', sortable: true },
        { text: 'Granted Shares', value: 'granted_shares', sortable: true },
        { text: 'Percent', value: 'ratio_fully_diluted', sortable: true },
      ],
    }),

    methods: {
      getDate (date) {
        return moment(date).format('MM/DD/YYYY')
      },
      calculateSum (roundData, key) {
        let total = 0
        if (roundData && roundData.investors && roundData.investors.length > 0) {
          return roundData.investors.reduce((accumulator, currentValue) => {
            (total += +currentValue[key])
            return total
          }, 0)
        } else {
          return total
        }
      },
      getPercentValue (value) {
        if (!isNaN(value)) {
          return (value * 100).toFixed(2) + '%'
        } else {
          return 'NA'
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .summary-section  .col  span {
    display: inline-block;
    width: 47%;
  }
  .summary-section  .col  span:last-child {
    vertical-align: top;
  }
  ::v-deep .scenario-card {
    .card-item {
      min-height: 400px;
    }
  }
  .footer-bg {
    background: var(--v-accent-base);
  }
</style>
